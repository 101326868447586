const CONTROLS_VISIBILITY_SELECTOR = "aria-hidden";
const CUSTOM_ELEMENT_ID = "banner-slider-fullsize";

class BannerSliderFullsize extends HTMLElement {
  constructor() {
    super();
    this.list = this.querySelector(".item-list");
    this.elements = this.querySelectorAll(".list-item");
    this.firstListElement = this.list.firstElementChild;
    this.lastListElement = this.list.lastElementChild;
    this.buttonPrevious = this.querySelector(".control[data-direction='prev']");
    this.buttonNext = this.querySelector(".control[data-direction='next']");
    this.tabContainer = this.querySelector(".tab-navigation");
    this.tabs = this.querySelectorAll(".tab");
    this.tabClickHandlerBound = this.tabClickHandler.bind(this);
    this.loopInterval = null;
  }

  get singleEntryWidth() {
    const element = this.list.firstElementChild;
    const elementStyle = element.currentStyle || window.getComputedStyle(element);
    const breite = element.offsetWidth;
    const margin = parseFloat(elementStyle.marginRight) + parseFloat(elementStyle.marginLeft);
    const border = parseFloat(elementStyle.borderRightWidth) + parseFloat(elementStyle.borderLeftWidth);
    return breite + margin + border;
  }

  get activeTab() {
    return this.querySelector(".tab[active]");
  }

  disconnectedCallback() {
    // Aufräumen
    for (const tab of this.tabs) {
      tab.removeEventListener("click", this.tabClickHandlerBound);
    }
  }
  
  connectedCallback() {
    this.initControls();
    this.initTabs();
    this.initBannerObserver();
    this.loop();
  }

  initTabs() {
    for (const tab of this.tabs) {
      tab.addEventListener("click", this.tabClickHandlerBound);
    }
  }

  tabClickHandler(event) {
    const { target } = event;
    const oldPosition = parseInt(this.activeTab.dataset["position"]);
    const newPosition = parseInt(target.dataset["position"]);
    const direction = newPosition > oldPosition ? "next" : "prev";
    const test = Math.abs(newPosition - oldPosition);
    this.setActiveTab(newPosition);
    this.updateBannerSliderPosition(direction, test);
    this.stopLoop();
  }

  initBannerObserver() {
    const callback = (entries, observer) => this.bannerObserverCallback(entries, observer);
    this.startIntersectionObserver(this.elements, callback);
  }

  startIntersectionObserver(observables, callback) {
    if (!observables || !callback) return;
    const options = {
      root: this,
      threshold: [0.9, 0.95, 1.0]
    };
    const observer = new IntersectionObserver(callback, options);
    if (!observables.length) {
      observer.observe(observables);
    } else {
      for (const element of observables) {
        observer.observe(element);
      }
    }
    return observer;
  }

  bannerObserverCallback(entries, observer) {
    for (const entry of entries) {
      const { target, intersectionRatio } = entry;
      const entryFullyVisible = intersectionRatio >= 0.9;
      const position = target.dataset["position"];
      this.updateControlElements(entry, entryFullyVisible);
      if (entryFullyVisible) {
        this.setActiveTab(position);
      }
    }
  }

  updateControlElements(entry, entryFullyVisible) {
    let button;
    const { target } = entry;
    switch (target) {
      case this.firstListElement:
        button = this.buttonPrevious;
        break;
      case this.lastListElement:
        button = this.buttonNext;
        break;
      default:
        break;
    }
    if (!button) {
      return false;
    }
    button.setAttribute(CONTROLS_VISIBILITY_SELECTOR, entryFullyVisible);
  }

  setActiveTab(position) {
    if (this.activeTab) {
      this.activeTab.removeAttribute("active");
    }
    this.querySelector(`.tab[data-position="${position}"]`)?.setAttribute("active", "");
  }

  async initControls(controlsHidden = false) {
    const controlElements = [this.buttonNext, this.buttonPrevious];
    for (const control of controlElements) {
      if (!controlsHidden && control === this.buttonNext && this.elements.length > 1) {
        this.showKontrollelement(control);
      }
      control.addEventListener("click", (event) => {
        this.controlClickHandler(event);
        this.stopLoop();
      });
    }
  }
  
  showKontrollelement(element) {
    element.setAttribute(CONTROLS_VISIBILITY_SELECTOR, "false");
  }
  
  hideKontrollelement(element) {
    element.setAttribute(CONTROLS_VISIBILITY_SELECTOR, "true");
  }
  
  controlClickHandler(event) {
    const direction = event.target.dataset["direction"];
    this.updateBannerSliderPosition(direction);
  }

  updateBannerSliderPosition(direction, multiplier = 1) {
    const left = (direction === "next" ? this.singleEntryWidth : - this.singleEntryWidth) * multiplier;
    this.scrollToPosition(this.list.scrollLeft + left);
  }

  scrollToPosition(positionLeft) {
    this.list.scrollTo({
      left: positionLeft,
      behavior: "smooth"
    });
  }

  stopLoop() {
    clearInterval(this.loopInterval);
  }

  loop() {
    this.loopInterval = setInterval(() => {
      const nextTab = (this.activeTab.dataset.position) % this.tabs.length;
      this.updateBannerSliderPosition("next");
      if (nextTab === 0) {
        this.scrollToPosition(0);
        this.stopLoop();
      }
    }, 5000);
  }

}

if (!customElements.get(CUSTOM_ELEMENT_ID)) {
  customElements.define(CUSTOM_ELEMENT_ID, BannerSliderFullsize);
}
